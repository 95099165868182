import Vue from 'vue';
import Router from 'vue-router';

import ItemsPage from '../business/ItemsPage.vue'
import ItemEditPage from '../business/ItemEditPage.vue'
import ItemsListForPrint from '../business/ItemsListForPrint.vue'
import LoginPage from '../login/LoginPage.vue'


Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      component: ItemsPage,
      name: 'items',
    },
    {
      path: '/login',
      component: LoginPage
    },
    {
      path: '/list-for-print',
      component: ItemsListForPrint,
      name: 'list-for-print'
    },
    {
      path: '/:id',
      component: ItemEditPage,
      name: 'item'
    },
    // otherwise redirect to home
    // {
    //   path: '*',
    //   redirect: '/'
    // }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})