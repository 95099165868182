<template>
  <div>
    <template>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table :items-per-page="30" dense :headers="headers" :items="tools" :search="search" sort-by="id"
          :sort-desc="true" class="elevation-1">

          <template v-slot:top="{ pagination, options, updateOptions }">

            <!-- Разместить pagination сверху -->
            <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText" />


            <v-toolbar flat>
              <v-toolbar-title>Инструменты GIP GROUP</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">


                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    Добавить инструмент
                  </v-btn>

                  <router-link :to="{ name: 'list-for-print'}">

                    <v-btn color="primary" dark class="mb-2">

                      Для печати
                    </v-btn>
                  </router-link>

                  <v-btn color="primary" dark class="mb-2">

                    <download-excel :data="tools">
                      .xlsx
                    </download-excel>
                  </v-btn>

                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.title" label="Наименование"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.brand" label="Бренд"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.model" label="Модель"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.ttype" label="Тип"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.serial_number" label="Серийный номер"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.polka_number" label="Номер полки"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.comment" label="Комментарий"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">

                          <v-text-field v-model="editedItem.poverka_date" label="YYYY-MM-DD Плановая дата поверки">
                          </v-text-field>

                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Отменить
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Уверены, что хотите удалить инструмент?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            <router-link :to="{ name: 'item', params: { id: item.id } }">{{ item.title }}</router-link>
          </template>

          <template v-slot:[`item.modified`]="{ item }">
            {{ item.modified | formatDate }}
          </template>

          <!-- ПОКА QR КОДЫ УБРАЛ ИЗ ОТОБРАЖЕНИЯ
          <template v-slot:[`item.qr_link`]="{ item }">
            <div v-bind:id="'print-' + item.id">
              <Vue-qr-code :margin=1 :scale=3 :value="item.qr_link" />
            </div>
          </template> -->

          <template v-slot:[`item.responsible_person`]="{ item }">
            <div v-if="item.responsible_person">
              {{ item.responsible_person.first_name }}
              {{ item.responsible_person.last_name }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status | location_convert }}
          </template>
          <template v-slot:[`item.poverka_date`]="{ item }">
            <p v-html="linkifyMethod(item.poverka_date)"></p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="user_data.email=='demo@gip.su'">
                no action permitted
            </div>
            <div v-else>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </div>

            <!-- <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

    </template>
    <v-footer color="red" padless>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 text-center">
          {{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
          <a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-weblineindia-qrcode'

export default {
  components: {
    VueQrCode,
  },
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    users() {
      return this.$store.state.users.all;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Новый инструмент' : 'Отредактировать инструмент'
    },
  },

  data: () => ({
    tools: [],
    search: '',
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'id',
        sortable: true,
        filterable: true,
        value: 'id',
      },
      {
        text: 'Наименование',
        align: 'start',
        filterable: true,
        value: 'title',
      },
      { text: 'Бренд', value: 'brand' },
      { text: 'Модель', value: 'model' },
      { text: 'Тип', value: 'ttype' },
      { text: 'Серийный номер', value: 'serial_number' },
      { text: 'Место размещения', value: 'status' },
      { text: 'Номер полки', value: 'polka_number' },
      { text: 'Дата движения', value: 'modified', filterable: false },
      { text: 'Ответственный', value: 'responsible_person' },
      { text: 'Плановая дата поверки', value: 'poverka_date', filterable: false },
      { text: 'Комментарий', value: 'comment' },
      // { text: 'QR', value: 'qr_link' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      title: '',
      brand: '',
      model: '',
      ttype: '',
      poverka_date: '',
      comment: '',
      serial_number: '',
      polka_number: '',
    },
    defaultItem: {
      title: '',
      brand: '',
      model: '',
      ttype: '',
      poverka_date: '2030-01-01',
      comment: '',
      serial_number: '',
      polka_number: '',
    },
    user_data: {},

  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    window.location.href = 'https://lk2.gip.su/gip/tools/eq'
    this.initialize()
  },

  methods: {
    initialize() {


      let user = JSON.parse(localStorage.getItem('user'));
      this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
      let self = this
      this.$axios
        .get(process.env.VUE_APP_BACKENDAPI + 'v1/items/')
        .then(function (response) {
          self.tools = response.data
        });

      let _user = JSON.parse(localStorage.getItem('user'));

      if (_user) {
        let self = this;
        this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
          .then(function (response) {
            localStorage.setItem('user_data', JSON.stringify(response.data));
            self.user_data = JSON.parse(localStorage.getItem('user_data'))
          });
      }


    },

    editItem(item) {
      this.editedIndex = this.tools.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true

    },

    deleteItem(item) {
      this.editedIndex = this.tools.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.tools.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // изменение объекта

        let user = JSON.parse(localStorage.getItem('user'));
        let self = this;
        this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
        this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.editedItem.id + '/',
          {
            title: this.editedItem.title,
            brand: this.editedItem.brand,
            model: this.editedItem.model,
            ttype: this.editedItem.ttype,
            serial_number: this.editedItem.serial_number,
            polka_number: this.editedItem.polka_number,
            poverka_date: this.editedItem.poverka_date,
            comment: this.editedItem.comment,
          },
          {
          }).then(function (response) {
            self.initialize()
            self.$notify({
              title: 'Успешно',
              text: 'Данные по инструменту обновлены',
              type: 'success',
              duration: 5000,
            });
            self.close()
          });




      } else {

        // создание объекта
        let user = JSON.parse(localStorage.getItem('user'));
        let user_data = JSON.parse(localStorage.getItem('user_data'));
        let self = this;
        this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
        this.$axios.post(process.env.VUE_APP_BACKENDAPI + 'v1/items/',
          {
            title: this.editedItem.title,
            brand: this.editedItem.brand,
            model: this.editedItem.model,
            ttype: this.editedItem.ttype,
            serial_number: this.editedItem.serial_number,
            polka_number: this.editedItem.polka_number,
            responsible_person_id: user_data.id,
            poverka_date: this.editedItem.poverka_date,
            comment: this.editedItem.comment,
          },
          {
          }).then(function (response) {
            self.initialize()
            self.$notify({
              title: 'Успешно',
              text: 'Инструмент успешно создан',
              type: 'success',
              duration: 5000,
            });
            self.close()
          }).catch((error) => {
            if (error.response) {

              self.$notify({
                title: 'Ошибка',
                text: error.response.data,
                type: 'error',
                duration: 5000,
              });
            }
          });;

      }
      this.close()
    },
    linkifyMethod(value) {
      let bred1 = '<span class="v-chip theme--light v-size--default red"><span class="v-chip__content">'
      let e1 = '</span></span>'
      let byellow2 = '<span class="v-chip theme--light v-size--default yellow"><span class="v-chip__content">'
      let e2 = '</span></span>'
      let b3 = '<span class="v-chip v-chip--no-color theme--light v-size--default"><span class="v-chip__content">'
      let e3 = '</span></span>'
      if (value) {
        if (moment(String(value)) < moment()) {
          return bred1 + moment(String(value)).format('DD.MM.YYYY') + e1
        }
        if (moment(String(value)) < moment().add(1, 'months')) {
          return byellow2 + moment(String(value)).format('DD.MM.YYYY') + e2
        }
        return b3 + moment(String(value)).format('DD.MM.YYYY') + '</v-chip>' + e3
      }
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('HH:mm - DD.MM.YYYY')
      }
    },
    location_convert: function (value) {
      if (value == 'not_located') {
        return 'Локация не определена'
      }
      if (value == 'on_project') {
        return 'На объекте'
      }
      if (value == 'on_office') {
                return 'В офисе'
            }
      if (value == 'on_repair') {
        return 'В ремонте'
      }
      if (value == 'on_warehouse') {
        return 'На складе'
      }
      if (value == 'on_lost') {
        return 'Утерян'
      }
    }
  }
}
</script>
