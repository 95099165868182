<template>
    <v-container v-if="tools">

        <v-row>
            <v-col v-for="(tool, index) in tools" :key="index" cols=3 class="text-center">

                <v-card elevation="2" outlined>
                    <br>

                    <div style="font-size: xx-small">
                        {{tool.title}} // id {{tool.id}}<br>
                        {{tool.brand}}<br>
                        {{tool.model}}<br>
                    </div>
                    <Vue-qr-code :margin=1 :scale=3 :value="tool.qr_link" />
                    <div style="font-size: xx-small">
                        tools ordering service
                        <br>
                        powered by GIP GROUP
                        <br>
                        gip-group.su
                        <br>
                        <br>

                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import VueQrCode from 'vue-weblineindia-qrcode'


export default {
    components: {
        VueQrCode,
    },
    name: 'ItemEditPage',
    data() {
        return {
            user_data: {},
            users: [],
            tools: [],
        }
    },
    computed: {
    },
    created() {
        this.initialize()
    },
    methods: {
        initialize() {

            let _user = JSON.parse(localStorage.getItem('user'));

            if (_user) {
                let self = this;
                this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
                    .then(function (response) {
                        localStorage.setItem('user_data', JSON.stringify(response.data));
                        self.user_data = JSON.parse(localStorage.getItem('user_data'))
                    });
            }


            this.user_data = JSON.parse(localStorage.getItem('user_data'));

            let user = JSON.parse(localStorage.getItem('user'));
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            let self = this;
            this.$axios
                .get(process.env.VUE_APP_BACKENDAPI + 'users/')
                .then(function (response) {
                    self.users = response.data
                    localStorage.setItem('users', JSON.stringify(response.data));

                });

            this.$axios
                .get(process.env.VUE_APP_BACKENDAPI + 'v1/items/')
                .then(function (response) {
                    self.tools = response.data
                });

        },
    },
    filters: {
    }
}
</script>
