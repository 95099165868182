<template>
    <div>


        <v-container fill-height fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="4">
                    <h2>Вход</h2>
                    <v-card elevation="2" outlined class="mx-auto">
                        <div class="">
                            <v-text-field type="text" label="Пользователь" v-model="username" name="username"
                                :class="{ 'is-invalid': submitted && !username }" />
                            <div v-show="submitted && !username" class="invalid-feedback">Пользователь, не почта!</div>
                        </div>
                        <div class="">
                            <v-text-field label="password" type="password" v-model="password" name="password"
                                :class="{ 'is-invalid': submitted && !password }" />
                            <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                        </div>
                        <div class="">
                            <v-btn block v-on:click="handleSubmit" :disabled="loggingIn">Войти</v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-footer color="red" padless fixed>
            <v-card class="flex" flat tile>
                <v-card-text class="py-2 text-center">
                    {{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
                    <a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
                </v-card-text>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            submitted: false
        }
    },
    computed: {
        loggingIn() {
            return this.$store.state.authentication.status.loggingIn;
        }
    },
    created() {
        // reset login status
        this.$store.dispatch('authentication/logout');
    },
    methods: {
        handleSubmit(e) {
            this.submitted = true;
            const { username, password } = this;
            const { dispatch } = this.$store;
            if (username && password) {
                dispatch('authentication/login', { username, password });
            }
        }
    }
};
</script>