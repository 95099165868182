import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('h2',[_vm._v("Вход")]),_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"2","outlined":""}},[_c('div',{},[_c(VTextField,{class:{ 'is-invalid': _vm.submitted && !_vm.username },attrs:{"type":"text","label":"Пользователь","name":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.username),expression:"submitted && !username"}],staticClass:"invalid-feedback"},[_vm._v("Пользователь, не почта!")])],1),_c('div',{},[_c(VTextField,{class:{ 'is-invalid': _vm.submitted && !_vm.password },attrs:{"label":"password","type":"password","name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.password),expression:"submitted && !password"}],staticClass:"invalid-feedback"},[_vm._v("Password is required")])],1),_c('div',{},[_c(VBtn,{attrs:{"block":"","disabled":_vm.loggingIn},on:{"click":_vm.handleSubmit}},[_vm._v("Войти")])],1)])],1)],1)],1),_c(VFooter,{attrs:{"color":"red","padless":"","fixed":""}},[_c(VCard,{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"py-2 text-center"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("powered by GIP GROUP")]),_vm._v(" "),_c('br'),_c('a',{attrs:{"href":"https://gip-group.su"}},[_c('strong',[_vm._v("https://gip-group.su")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }