import Vue from 'vue';
import vuetify from './plugins/vuetify' // path to vuetify export
import axios from 'axios'
import JsonExcel from "vue-json-excel";


import {
    store
} from './_store';
import {
    router
} from './_helpers';
import App from './app/App.vue';
import Notifications from 'vue-notification'

/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/


Vue.prototype.$axios = axios
Vue.use(Notifications)
Vue.component("downloadExcel", JsonExcel);

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();

new Vue({
    vuetify,
    el: '#app',
    router,
    store,
    components: {
        Notifications
    },
    render: h => h(App)
});