<template>
    <div v-if="user_data">
        <div v-if="user_data.email=='demo@gip.su'">
            Доступ запрещен
        </div>
        <div v-else>
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4" offset-md="4">
                        <b>Наименование прибора:</b> {{ item.title }} ({{item.brand}}, {{item.model}}) <br>
                        <b>Последнее движение прибора:</b> {{ item.modified | formatDate }} <br>
                        <div v-if="item.responsible_person">
                            <b>Последний ответственный:</b>
                            <v-chip>{{ item.responsible_person.first_name }} {{
                            item.responsible_person.last_name
                            }}
                            </v-chip> <br>
                        </div>
                        <b>Статус:</b> {{ item.status | location_convert }} <br>
                        <span v-if="item.comment">
                            <b>Комментарий:</b> {{ item.comment }} <br>
                        </span>
                        <span v-if="item.polka_number"> 
                            <b>Номер полки:</b> {{ item.polka_number }}  <br>
                        </span>
                        <span v-else>
                            <b>Номер полки:</b>  номер полки не указан <br>
                        </span>
                        <br>
                        <v-row align="center" justify="space-around">
                            <v-btn class="ma-2 pa-2" elevation="5" color="primary" large block
                                v-on:click="get_to_object()">
                                Взял
                                на объект
                            </v-btn>
                        </v-row>
                        <v-row align="center" justify="space-around">
                            <v-btn class="ma-2 pa-2" elevation="5" color="primary" large block
                                v-on:click="return_to_warehouse()">Вернул на
                                склад</v-btn>
                        </v-row>
                        <v-row align="center" justify="space-around">
                            <v-btn class="ma-2 pa-2" elevation="5" color="primary" large block
                                v-on:click="return_to_office()">Вернул в офис
                            </v-btn>
                        </v-row>
                        <v-row align="center" justify="space-around">
                            <v-btn class="ma-2 pa-2" elevation="5" color="primary" large block
                                v-on:click="get_to_repair()">
                                Отдал в ремонт
                            </v-btn>
                        </v-row>

                        <v-row align="center" justify="space-around">
                            <v-btn class="ma-2 pa-2" elevation="5" color="primary" large block v-on:click="get_lost()">
                                Утерян
                            </v-btn>
                        </v-row>
                        <v-row align="center" justify="space-around">
                            <v-btn class="ma-2 pa-2" elevation="5" color="primary" large block
                                v-on:click="set_no_location()">
                                Локация не определена
                            </v-btn>
                        </v-row>
                        <v-row justify="center">
                            <v-expansion-panels accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>История перемещений</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p v-for="hist in item.history" :key="hist.history_id">
                                            {{ hist.responsible_person_id | convert_id_to_name }} |
                                            {{ hist.modified | formatDate }} |
                                            {{ hist.status | location_convert }}
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>


                    </v-col>
                </v-row>
                <v-row >
                    <v-col cols="12" sm="12" md="2" offset-md="5" class="text-center">
                        <v-card elevation="5">
                            <br>
                            <div style="font-size: xx-small">
                                {{item.title}} // id {{item.id}}<br>
                                {{item.brand}}<br>
                                {{item.model}}<br>
                            </div>
                            <div v-if="item.qr_link">
                                <Vue-qr-code :margin=1 :scale=3 :value="item.qr_link" />
                            </div>
                            <div style="font-size: xx-small">
                                tools ordering service
                                <br>
                                powered by GIP GROUP
                                <br>
                                https://gip-group.su
                                <br>
                                <br>

                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>



            <v-footer color="red" padless>
                <v-card class="flex" flat tile>
                    <v-card-text class="py-2 text-center">
                        {{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
                        <a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
                    </v-card-text>
                </v-card>
            </v-footer>
        </div>
    </div>

</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-weblineindia-qrcode'


export default {
    components: {
        VueQrCode,
    },
    name: 'ItemEditPage',
    data() {
        return {
            item: {},
            user_data: {},
            users: []
        }
    },
    computed: {
        username() {
            // We will see what `params` is shortly
            return this.$route.params.id
        },
    },
    created() {
        window.location.href = 'https://lk2.gip.su/gip/tools/eqs/' + this.$route.params.id
        this.initialize()
    },
    methods: {
        initialize() {

            let _user = JSON.parse(localStorage.getItem('user'));

            if (_user) {
                let self = this;
                this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
                    .then(function (response) {
                        localStorage.setItem('user_data', JSON.stringify(response.data));
                        self.user_data = JSON.parse(localStorage.getItem('user_data'))
                    });
            }


            this.user_data = JSON.parse(localStorage.getItem('user_data'));



            let user = JSON.parse(localStorage.getItem('user'));
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            let self = this;
            this.$axios
                .get(process.env.VUE_APP_BACKENDAPI + 'users/')
                .then(function (response) {
                    self.users = response.data
                    localStorage.setItem('users', JSON.stringify(response.data));

                });

            this.$axios
                .get(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/')
                .then(function (response) {
                    self.item = response.data
                });


        },


        get_to_object() {
            let user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/',
                {
                    status: 'on_project',
                    responsible_person_id: this.user_data.id
                },
                {
                })
                .then(function (response) {
                    self.initialize()
                    self.$notify({
                        title: 'Успешно',
                        text: 'Статус изменен',
                        type: 'success',
                        duration: 5000,
                    });
                });
        },
        get_to_repair() {
            let user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/',
                {
                    status: 'on_repair',
                    responsible_person_id: this.user_data.id
                },
                {
                }).then(function (response) {
                    self.initialize()
                    self.$notify({
                        title: 'Успешно',
                        text: 'Статус изменен',
                        type: 'success',
                        duration: 5000,
                    });
                });
        },
        return_to_warehouse() {
            let user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/',
                {
                    status: 'on_warehouse',
                    responsible_person_id: this.user_data.id
                },
                {
                }).then(function (response) {
                    self.initialize()
                    self.$notify({
                        title: 'Успешно',
                        text: 'Статус изменен',
                        type: 'success',
                        duration: 5000,
                    });
                });
        },
        return_to_office() {
            let user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/',
                {
                    status: 'on_office',
                    responsible_person_id: this.user_data.id
                },
                {
                }).then(function (response) {
                    self.initialize()
                    self.$notify({
                        title: 'Успешно',
                        text: 'Статус изменен',
                        type: 'success',
                        duration: 5000,
                    });
                });
        },
        get_lost() {
            let user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/',
                {
                    status: 'on_lost',
                    responsible_person_id: this.user_data.id
                },
                {
                }).then(function (response) {
                    self.initialize()
                    self.$notify({
                        title: 'Успешно',
                        text: 'Статус изменен',
                        type: 'warn',
                        duration: 5000,
                    });
                });
        },
        set_no_location() {
            let user = JSON.parse(localStorage.getItem('user'));
            let self = this;
            this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
            this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/items/' + this.$route.params.id + '/',
                {
                    status: 'not_located',
                    responsible_person_id: this.user_data.id
                },
                {
                }).then(function (response) {
                    self.initialize()
                    // using options
                    self.$notify({
                        title: 'Успешно',
                        text: 'Статус изменен',
                        type: 'warn',
                        duration: 5000,
                    });
                });
        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                return moment(String(value)).format('HH:mm - DD.MM.YYYY')
            }
        },
        location_convert: function (value) {
            if (value == 'not_located') {
                return 'Локация не определена'
            }
            if (value == 'on_project') {
                return 'На объекте'
            }
            if (value == 'on_office') {
                return 'В офисе'
            }
            if (value == 'on_repair') {
                return 'В ремонте'
            }
            if (value == 'on_warehouse') {
                return 'На складе'
            }
            if (value == 'on_lost') {
                return 'Утерян'
            }
        },
        convert_id_to_name(responsible_person_id) {
            let users = JSON.parse(localStorage.getItem('users'));

            let responsible_person_data = users.find(x => x.id === responsible_person_id)
            return responsible_person_data.first_name + " " + responsible_person_data.last_name

        },

    }
}
</script>
